@font-face {
    font-family: cabinitalic;
    src: url(../../fonts/cabin-italic.63c4548e.eot);
    src: url(../../fonts/cabin-italic.63c4548e.eot?#iefix) format("embedded-opentype"), url(../../fonts/cabin-italic.541bda64.woff2) format("woff2"), url(../../fonts/cabin-italic.93316116.woff) format("woff"), url(../../fonts/cabin-italic.27bbc88c.ttf) format("truetype"), url(../../fonts/cabin-italic.9f776937.svg#cabinitalic) format("svg");
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: cabinsemibold;
    src: url(../../fonts/cabin-semibold.363183af.eot);
    src: url(../../fonts/cabin-semibold.363183af.eot?#iefix) format("embedded-opentype"), url(../../fonts/cabin-semibold.73d1b278.woff2) format("woff2"), url(../../fonts/cabin-semibold.ca5e2a59.woff) format("woff"), url(../../fonts/cabin-semibold.02c8c42b.ttf) format("truetype"), url(../../fonts/cabin-semibold.9f5c6a7b.svg#cabinsemibold) format("svg");
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: cabinregular;
    src: url(../../fonts/cabin-regular.c9e0fb87.eot);
    src: url(../../fonts/cabin-regular.c9e0fb87.eot?#iefix) format("embedded-opentype"), url(../../fonts/cabin-regular.91e3abdf.woff2) format("woff2"), url(../../fonts/cabin-regular.5a872695.woff) format("woff"), url(../../fonts/cabin-regular.fabcb143.ttf) format("truetype"), url(../../fonts/cabin-regular.b8e6b14e.svg#cabinregular) format("svg");
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: cabinbold;
    src: url(../../fonts/cabin-bold.bbc4b245.eot);
    src: url(../../fonts/cabin-bold.bbc4b245.eot?#iefix) format("embedded-opentype"), url(../../fonts/cabin-bold.b2a648eb.woff2) format("woff2"), url(../../fonts/cabin-bold.33af8671.woff) format("woff"), url(../../fonts/cabin-bold.251c659e.ttf) format("truetype"), url(../../fonts/cabin-bold.c7a7f03a.svg#cabinbold) format("svg");
    font-weight: 400;
    font-style: normal
}
@font-face {
    font-family: alfa_slab_oneregular;
    src: url(../../fonts/alfaslabone-regular.2d2990b2.eot);
    src: url(../../fonts/alfaslabone-regular.2d2990b2.eot?#iefix) format("embedded-opentype"), url(../../fonts/alfaslabone-regular.01028a37.woff2) format("woff2"), url(../../fonts/alfaslabone-regular.e710e11b.woff) format("woff"), url(../../fonts/alfaslabone-regular.f11994d4.ttf) format("truetype"), url(../../fonts/alfaslabone-regular.d6f65322.svg#alfa_slab_oneregular) format("svg");
    font-weight: 400;
    font-style: normal
}